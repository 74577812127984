//jQuery(function ($) {
    var $window = $(window);

    $('.nav-toggle').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('opened');
        $('.mobile-menu').slideToggle();
    });

    $('.toggle-cats a').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);

        var $parent = $this.parents('.toggle-cats');
        $parent.find('li').removeClass('current');
        $this.parent().addClass('current');

        var cat = $this.data('link');
        var target = $parent.data('target');
        $(target).find('.project').fadeOut(400, function () {
            $(this).removeClass("active");
        });
        $(target).find('.project[data-category="' + cat + '"]').fadeIn(400, function () {
            $(this).addClass("active");
        });
    });

    $('.file-input .btn').on('click', function () {
        $(this).parent().find('input[type="file"]').trigger("click");
    });

    $('.file-input input[type="file"]').change(function (e) {
        var filename = e.target.files[0].name;
        $(this).parents('.file-input').find('.label').html(filename);
    });    

    var url = window.location.pathname;
    $('.header-nav li').removeClass("current");
    $('.header-nav a[href="' + url + '"]').parent().addClass("current");

    $window.on("scroll", animate_milestone);
    var $animation_elements = $('.milestone-number:not(.animated)');

    function animate_milestone() {
        var window_height = $window.height();
        var window_top_position = $window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);

        $.each($animation_elements, function () {
            var $element = $(this);
            var element_height = $element.outerHeight();
            var element_top_position = $element.offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position) && !$element.hasClass('animated')) {
                var value = $element.data('stop');
                jQuery({
                        Counter: 0
                    }).delay(300)
                    .animate({
                        Counter: value
                    }, {
                        duration: 3000,
                        easing: 'swing',
                        step: function () {
                            $element.text(Math.ceil(this.Counter));
                            $element.addClass("animated");
                        }
                    });
            }
        });
    }

    //sólo números
    $('input[type="tel"]').keydown(function (e) {
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            return;
        }
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });

    // form validation
    window.addEventListener('load', function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                event.stopPropagation();

                var warning = form.querySelector('.alert');
                var success = form.querySelector('.alert-ok');
                var btn = form.querySelector('.btn-send');

                form.classList.add('was-validated');
                
                if (form.checkValidity() === false) {
                    $(warning).fadeIn();
                } else {
                    var formData = new FormData();
                    var prueba_1 = $(form).find('input[name="prueba_1"]');
                    var prueba_2 = $(form).find('input[name="prueba_2"]');
                    var prueba_3 = $(form).find('input[name="prueba_3"]');
                    var prueba_4 = $(form).find('input[name="prueba_4"]');

                    formData.append("nombres", $(form).find('input[name="nombres"]').val()); 
                    formData.append("apellidos", $(form).find('input[name="apellidos"]').val());
                    formData.append("telefono", $(form).find('input[name="telefono"]').val());
                    formData.append("correo", $(form).find('input[name="correo"]').val());
                    formData.append("direccion", $(form).find('input[name="direccion"]').val());
                    formData.append("relacion_hechos", $(form).find('textarea[name="relacion_hechos"]').val());
                    //formData.append("tipo", $(form).find('input[name="tipo"]:checked').val());
                    formData.append("form[_token]", $(form).find('input[name="form[_token]"]').val());
                    formData.append('prueba_1', prueba_1[0].files[0]);
                    formData.append('prueba_2', prueba_2[0].files[0]);
                    formData.append('prueba_3', prueba_3[0].files[0]);
                    formData.append('prueba_4', prueba_4[0].files[0]);


                    //data = $(form).serialize();
                    $(btn).text('Enviando...');
                    $(btn).prop('disabled', true);
                    $(warning).fadeOut();

                    grecaptcha.ready(function(){
                        grecaptcha.execute('6LdKBKMUAAAAAFkJEJHYdZjfL7JY3Hypi3gAKYwL', {action: 'enviar_denuncia'}).then(function(token){
                            //data += '&token='+token;  
                            formData.append("token", token);
                            action = $(form).attr("action");

                            $.ajax({
                                method: "POST",
                                url: action,
                                data: formData,                                
                                dataType: 'json',
                                cache: false,
                                contentType: false,
                                processData: false
                            }).done(function(result){
                                console.log(result);
                                if (result.data == 'error_token' || result.data == 'error_verify' || result.data == 'error_invalid')
                                    $(warning).text('Ocurrió un error al enviar el mensaje.').fadeIn();
                                else if (result.data == 'error_empty')
                                    $(warning).text('Revise los campos requeridos.').fadeIn();
                                else if (result.data == 'error_email')
                                    $(warning).text('Correo electrónico inválido.').fadeIn();
                                else if (result.data == 'error_extension' || result.data == 'error_size')
                                    $(warning).text('Archivo/s inválido/s o muy pesado/s').fadeIn();
                                else if (result.data == 'error_corrupted')
                                    $(warning).text('El archivo '+ result.file +' no es válido o está corrupto.').fadeIn();
                                else
                                    $(success).fadeIn();

                                $(btn).text('Enviar');
                                $(btn).prop('disabled', false);
                                form.reset();
                                $(form).find('.file-input .label').text('Ningún archivo seleccionado');
                                $(form).removeClass('was-validated');

                                setTimeout(function () {
                                    $(warning).fadeOut();
                                    $(success).fadeOut();
                                }, 4000);
                            });
                        });
                    });
                }                
            }, false);
        });
    }, false);

    $window.scroll(function () {
        scrollActions();
    });

    function scrollActions() {
        if ($window.scrollTop() >= 50)
            $("#up").fadeIn(200);
        else
            $("#up").fadeOut(200);

        if ($window.scrollTop() >= 200)
            $('.site-header').addClass('fixed');
        else
            $('.site-header').removeClass('fixed');
    }

    scrollActions();

    $("#up a").on('click', function (e) {
        e.preventDefault();
        $('body, html').animate({
            scrollTop: 0
        }, 500);
    });


    // privacidad/cookies
    var cookies = getCookie("cookies_enabled");

    if (cookies === "yes")
        $('.privacidad-bar').remove();

    $('.privacidad-bar .accept').on('click', function (e) {
        e.preventDefault();
        setCookie("cookies_enabled", "yes", 365);
        $(this).parents('.privacidad-bar').slideUp('slow', function () {
            $(this).remove();
        });
    });

    function setCookie(cname, cvalue, exdays) {
        if(exdays > 0)
        {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        } else 
            document.cookie = cname + "=" + cvalue;
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // suscripcion
    //-cerrar
    $('.suscripcion a').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.suscripcion').fadeOut(400, function () {
            $(this).remove();
            $('body').removeClass('stop-overflow');
        });
    });
    //-action
    $(".subscribe-form").submit(function (e) {
        e.preventDefault();
        var $this = $(this);
        var $btn = $this.find('button[type="submit"]');
        var btn_text = $btn.text();
        var $mensaje = $this.next();
        
        // if( ( !empty($post['informe']) && ( empty($nombre) || empty($email) ) ) || 
        //    ( empty($post['informe']) && ( empty($nombre) || empty($apellido) || empty($email) ) )
        //)

        if (
            ( $this.find('input[name="informe"]').length && 
                (
                    ($this.find('input[name="nombre"]').val() === '') || ($this.find('input[name="email"]').val() === '')
                ) 
            ) || 
            ( !$this.find('input[name="informe"]').length && 
                (
                    ($this.find('input[name="nombre"]').val() === '') || ($this.find('input[name="apellido"]').val() === '') || ($this.find('input[name="email"]').val() === '')
                ) 
            )
        )
            $mensaje.text('*** Todos los campos son requeridos.').fadeIn();        
        else 
        {
            var formdata = $this.serialize();
            $btn.prop('disabled', true);
            $btn.text('Espere...');

            grecaptcha.ready(function () {
                grecaptcha.execute('6LdKBKMUAAAAAFkJEJHYdZjfL7JY3Hypi3gAKYwL', {
                    action: 'suscripcion'
                }).then(function (token) {
                    formdata += "&token=" + token; 

                    $.post('/suscribir', formdata, function (result) {
                        // console.log(result);
                        if (result.data == 'error_token' || result.data == 'error_verify' || result.data == 'error_invalid' || result.data == 'error_config' || result.data == 'error_mailchimp' || result.data == 'error_connection' || result.data == 'error_notfound')
                            $mensaje.text('*** Ocurrió un error en la solicitud.').fadeIn();
                        else if (result.data == 'error_empty')
                            $mensaje.text('*** Todos los campos son requeridos.').fadeIn();
                        else if (result.data == 'error_email')
                            $mensaje.text('*** Correo electrónico inválido.').fadeIn();
                        else if (result.data == 'error_subscribed')
                            $mensaje.text('Ya te has suscrito. ¡Gracias! El archivo se está descargando.').fadeIn();                        
                        else
                            $mensaje.text('¡Gracias por suscribirte! El archivo se está descargando.').fadeIn(); 

                        if (result.data == 'error_subscribed' || result.data == 1)
                        {
                            $this[0].reset();

                            setCookie("bioep_shown", true, 365);
                            setCookie("bioep_shown_session", true);
                        }

                        if (result.file_url !== undefined && result.file_url !== '')
                        {
                            var link = $('<a>').attr("href", result.file_url).attr("download", result.file_name).appendTo("body");
                            // var link = $('<a>').attr("href", result.file_url).attr("download", result.file_name).appendTo(".subscription-form");
                            link[0].click();
                            link.remove();
                        }

                        $btn.prop('disabled', false);
                        $btn.text(btn_text);
                    });
                });
            });
        }        
    });

    bioEp.init({
        delay: 5,
        html: '',
        css: '',
        cookieExp: 1
    });

    /*
    $(window).load(function () {
        $('.spinner-container').fadeOut(400, function () {
            $('body').removeClass('stop-overflow');
        });
    });
    */

    $('.btn-download').on('click', function (e) {
        e.preventDefault();
        $('.subscription-form').slideDown();
    });

    // load more items
    var page = 1;
    $('body').on('click', 'a.load-more-timeline', function(e){
        e.preventDefault();
        var $this = $(this);

        if($this.hasClass('disabled'))
            return;

        ++page;
        var url = $this.attr('href');
        $this.addClass('disabled');
        var text = $this.text();
        $this.text('Cargando...');
        
        $.post(url+"/"+page, function(response){
            $this.text(text);
            $this.removeClass('disabled');

            if(response.data != 'empty')
                $('.timeline-load-more').append(response.data);
            if(response.last === true)
                $this.remove();
        }, 'json');
    });

    var pr_page = 1;
    $('.load-more-pr').on('click',function(e){
        e.preventDefault();
        var $this = $(this);

        if ($this.hasClass('disabled'))
            return;

        ++pr_page;
        var url = $this.attr('href');
        $this.addClass('disabled');
        var text = $this.text();
        $this.text('Cargando...');

        $.post(url + "/" + pr_page, function (response) {
            $this.text(text);
            $this.removeClass('disabled');

            if (response.data != 'empty')
                $('.pr-load-more-box').append(response.data);
            if (response.last === true)
                $this.remove();
        }, 'json');
    });
//});