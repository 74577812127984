/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
require('./bioep.js');
const $ = require('jquery');
require('./main.js');

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    delay: 300,
    duration: 800
});

global.WebFontConfig = {
    google: {
        families: ['Lato:300,400,700', 'Roboto Slab:700']
    }
};

(function (d) {
    var wf = d.createElement('script'), s = d.scripts[0];
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.async = true;
    s.parentNode.insertBefore(wf, s);
})(document);